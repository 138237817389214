import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { AxiosResponse } from "axios";
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType } from "@zxing/library";
import { useAxios } from "../AxiosContext";

interface Props {
    onSubmit: (sku: Sku) => Promise<AxiosResponse<any>>;
    onCancel: () => void;
}

type Ean = string

interface Sku {
    ean: Ean
    name: string
    sugar: number | null;
    portion: Portion;
}

interface Portion {
    weight: number | null;
    description: string;
}

interface SkuLookupResult {
    name: string[];
    sugar: number[];
    portionWeight: number[];
}

interface RawPortionWeight {
    portionWeight: number | null
    divideBy: number | null
}

function NewSku({ onSubmit, onCancel }: Props) {

    const emptySku = { ean: "", name: "", sugar: null, portion: { weight: null, description: "1 sztuka" } } // FIXME

    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [scanning, setScanning] = useState(false)

    const [sku, setSku] = useState<Sku>(emptySku)

    const [lookupResult, setLookupResult] = useState<SkuLookupResult>({ name: [], sugar: [], portionWeight: [] })

    const [rawPortionWeight, setRawPortionWeight] = useState<RawPortionWeight>({ portionWeight: null, divideBy: 1 })

    const axios = useAxios()

    const reader = useRef<BrowserMultiFormatReader | null>(null);

    const lookup = useCallback(async (ean: Ean) => {
        setLoading(true)
        try {
            const response = await axios.get(`/v1/sugar/sku-lookup?ean=${ean}`)

            const newLookupResult = response.data

            setLookupResult(newLookupResult)

            if (newLookupResult.name.length === 1) {
                setSku((sku) => ({ ...sku, name: newLookupResult.name[0] }))
            }
    
            if (newLookupResult.portionWeight.length === 1) {
                setSku((sku) => ({ ...sku, portion: { ...sku.portion, weight: newLookupResult.portionWeight[0] } }))
                setRawPortionWeight((rawPortionWeight) => ({ ...rawPortionWeight, portionWeight: newLookupResult.portionWeight[0] }))
            }
    
            if (newLookupResult.sugar.length === 1) {
                setSku((sku) => ({ ...sku, sugar: newLookupResult.sugar[0] }))
            }

            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }, [axios])

    const create = () => {
        setLoading(true)
        onSubmit(sku).then(response => {
            console.log("Done", response)
            reset()
        }).catch(error => {
            console.error(error)
        }) // TODO an actual error handling?
    }

    const reset = () => {
        reader.current?.stopAsyncDecode()
        reader.current?.stopContinuousDecode()
        reader.current?.reset()

        setSku(emptySku)
        setLoading(false)
        setStep(1)
    }

    const stopBarcodeReader = useCallback(() => {
        reader.current?.stopAsyncDecode()
        reader.current?.stopContinuousDecode()
        reader.current?.reset()

        setScanning(false)
    }, [reader])

    const initBarcodeReader = useCallback(async () => {
        setScanning(true)
        const hints = new Map();
        const formats = [BarcodeFormat.EAN_8, BarcodeFormat.EAN_13, BarcodeFormat.UPC_A, BarcodeFormat.UPC_E];
        hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
        reader.current = new BrowserMultiFormatReader(hints)
        reader.current.decodeFromVideoDevice(null, "barcode-video", (result, err) => {
            if (result) {
                const ean = result.getText()
                stopBarcodeReader()
                lookup(ean)
                setSku((sku) => ({ ...sku, ean: ean }))
                setStep(2)
            }
        })
    }, [reader, stopBarcodeReader, lookup])

    useEffect(() => {
        try {
            const {portionWeight, divideBy} = rawPortionWeight

            if (portionWeight && divideBy) {
                const weight = portionWeight / divideBy
                if (!isNaN(weight) && sku.portion.weight !== weight) {
                    setSku((sku) => ({ ...sku, portion: { ...sku.portion, weight: weight } }))
                } else if (sku.portion.weight !== null && sku.portion.weight !== weight) {
                    setSku((sku) => ({ ...sku, portion: { ...sku.portion, weight: null } }))
                }
            } else if (sku.portion.weight !== null) {
                setSku((sku) => ({ ...sku, portion: { ...sku.portion, weight: null } }))
            }
        } catch (e) {
            if (sku.portion.weight !== null) {
                setSku((sku) => ({ ...sku, portion: { ...sku.portion, weight: null } }))
            }
        }
    }, [rawPortionWeight, sku])

    useEffect(() => {
        initBarcodeReader()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // TODO cleanup of the barcode on unmount?

    return (
        <div>
            {
                loading ? <div className="text-center"><Spinner animation="border" /></div> : <>
                    {
                        step === 1 &&
                        <Form onSubmit={e => { e.preventDefault(); lookup(sku.ean); setStep(2) }}>
                            <Form.Group>
                                <Form.Label>EAN</Form.Label>
                                {!scanning && <Form.Control autoFocus type="text" placeholder="EAN" value={sku.ean} onChange={e => setSku({ ...sku, ean: e.target.value })} />}
                            </Form.Group>
                            <video id="barcode-video" style={{ display: scanning ? "block" : "none", "borderRadius": "0.5em" }} className="mb-3 w-100"></video>

                            <div className="text-center mt-3">
                                <Button variant="danger" onClick={e => onCancel()}>Cancel</Button>
                                {' '}
                                {scanning ? <Button onClick={() => stopBarcodeReader()}>Stop scanning</Button> : <Button variant="secondary" onClick={e => initBarcodeReader()}>Scan</Button>}
                                {' '}
                                <Button type="submit" disabled={sku.ean.length < 4}>Next</Button>
                            </div>
                        </Form>
                    }
                    {
                        step === 2 &&
                        <Form onSubmit={e => e.preventDefault()}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" value={sku.name} autoFocus placeholder="Name" onChange={e => setSku({ ...sku, name: e.target.value })} />
                            </Form.Group>
                            <ListGroup>
                                {
                                    lookupResult.name.map(name => {
                                        return (
                                            <ListGroup.Item action key={name} className={`d-flex align-items-center ${sku.name === name ? "active" : ""}`} disabled={sku.name === name} onClick={() => setSku({ ...sku, name: name })}>{name}</ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                            <div className="text-center mt-3">
                                <Button variant="secondary" onClick={e => setStep(1)}>Back</Button>
                                {' '}
                                <Button onClick={e => { setStep(3) }} disabled={sku.name.length < 3}>Next</Button>
                            </div>
                        </Form>
                    }
                    {
                        step === 3 &&
                        <Form onSubmit={e => { e.preventDefault() }}>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Portion description</Form.Label>
                                        <Form.Control type="text" placeholder="1 sztuka" value={sku.portion.description} onChange={e => setSku({ ...sku, portion: { ...sku.portion, description: e.target.value } })} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Portion weight [g]</Form.Label>
                                        <Form.Control type="text" value={rawPortionWeight.portionWeight || ""} autoFocus placeholder="34.5" onChange={e => {
                                            let portionWeight = parseFloat(e.target.value.replace(",", "."))

                                            setRawPortionWeight({ ...rawPortionWeight, portionWeight: portionWeight })
                                        }} />
                                    </Form.Group>
                                    <ListGroup>
                                        {
                                            lookupResult.portionWeight.map(weight => {
                                                return (
                                                    <ListGroup.Item
                                                        action
                                                        className={`d-flex align-items-center ${rawPortionWeight.portionWeight === weight ? "active" : ""}`}
                                                        key={weight}
                                                        disabled={rawPortionWeight.portionWeight === weight}
                                                        onClick={() => { setRawPortionWeight((rawPortionWeight) => ({ ...rawPortionWeight, portionWeight: weight })) }}
                                                    >
                                                        {weight.toFixed(1)} g
                                                    </ListGroup.Item>
                                                )
                                            })
                                        }
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Divide by</Form.Label>
                                        <Form.Control type="text" value={rawPortionWeight.divideBy || ""} onChange={e => {
                                            let divideBy = parseInt(e.target.value)
                                            if (!isNaN(divideBy)) {
                                                setRawPortionWeight({ ...rawPortionWeight, divideBy: divideBy })
                                            } else {
                                                setRawPortionWeight({ ...rawPortionWeight, divideBy: null })
                                            }
                                        }} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Calculated</Form.Label>
                                        <Form.Control className="text-center" plaintext readOnly value={sku.portion.weight ? (sku.portion.weight.toFixed(1) + " g") : "-"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="text-center">
                                    <Button variant="secondary" onClick={e => setStep(2)}>Back</Button>
                                    {' '}
                                    <Button onClick={() => setStep(4)} disabled={!(sku.portion.weight && sku.portion.description.length >= 3)}>Next</Button>
                                </Col>
                            </Row>

                        </Form>
                    }
                    {
                        step === 4 &&
                        <Form onSubmit={e => { e.preventDefault() }}>
                            <Form.Group>
                                <Form.Label>Sugar content [%]</Form.Label>
                                <Form.Control type="text" autoFocus placeholder="66.6" value={sku.sugar || ""} onChange={e => {
                                    let value = parseFloat(e.target.value.replace(",", "."))
                                    if (!isNaN(value)) {
                                        setSku({ ...sku, sugar: value })
                                    } else {
                                        setSku({ ...sku, sugar: null })
                                    }
                                }} />
                            </Form.Group>
                            <ListGroup>
                                {
                                    lookupResult.sugar.map(sugar => {
                                        return (
                                            <ListGroup.Item
                                                action
                                                className={`d-flex align-items-center ${sku.sugar === sugar ? "active" : ""}`}
                                                key={sugar}
                                                disabled={sku.sugar === sugar}
                                                onClick={() => { setSku({ ...sku, sugar: sugar }) }}
                                            >
                                                {sugar.toFixed(1)}%
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                            <div className="text-center mt-3">
                                <Button variant="secondary" onClick={e => setStep(3)}>Back</Button>
                                {' '}
                                <Button type="submit" variant="success" disabled={!sku.sugar} onClick={() => create()}>Create</Button>
                            </div>
                        </Form>
                    }</>
            }
        </div>
    )
}

export default NewSku